import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import showdown from "showdown"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "vielen-dank"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              text
              buttons {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`

export default function NotFound(props) {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const converter = new showdown.Converter({ 'simpleLineBreaks': true })
  
  return (
    <Layout>
      <Head title={data.title} robots="noindex, nofollow" />
      <div className="my-16 sm:my-24 lg:my-32">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-4xl mx-auto text-center">
            <h1 className="block text-4xl !leading-[1.1] font-serif text-petrol md:text-5xl xl:text-6xl">{data.title}</h1>
            <div className="mt-6 max-w-3xl mx-auto text-base md:text-lg leading-normal text-petrol" dangerouslySetInnerHTML={{__html: converter.makeHtml(data.text)}}></div>
            <div className="mt-10 flex gap-3 flex-wrap justify-center">

              {data.buttons.map((button, i) => {
              return (
                <div key={i} className="rounded-full">
                  <AniLink
                    paintDrip
                    to={button.link}
                    duration={1}
                    hex="F5DCAD"
                    className={i === 0 ? "inline-flex items-center justify-center px-8 py-3 md:px-10 md:py-4 border border-petrol text-base font-semibold rounded-full text-petrol bg-white hover:bg-green hover:border-green hover:text-white whitespace-nowrap transition-colors" : "inline-flex items-center justify-center px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-semibold rounded-full text-white bg-petrol hover:bg-green whitespace-nowrap transition-colors"}
                  >{button.text}</AniLink>
                </div>
              )
              })}
              
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
